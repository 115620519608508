/**
 * TofuUI DropDownMenu-Small 下拉菜单-变式
 * @author shuakami
 * @version 1.0.0
 * @copyright ByteFreeze&TofuUI
 */
import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface MenuItem {
  id: string;
  text: string;
  href?: string;
  target?: string;
  icon?: JSX.Element | React.ReactNode | string;
}

interface DropDownMenuProps {
  position?: 'top' | 'bottom' | 'left' | 'right';
  isOpen: boolean;
  menuItems: MenuItem[];
  className?: string;
  onClose?: () => void;
}

const DropDownMenu: React.FC<DropDownMenuProps> = ({
                                                     position = 'bottom',
                                                     isOpen,
                                                     menuItems,
                                                     onClose,
                                                     className = ''
                                                  }) => {

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const getPositionStyles = () => {
    switch (position) {
      case 'top':
        return { bottom: '100%', transform: 'translateY(-100%)' };
      case 'bottom':
        return { top: '100%', transform: 'translateY(0%)' };
      case 'left':
        return { right: '100%', transform: 'translateX(-100%)' };
      case 'right':
        return { left: '100%', transform: 'translateX(100%)' };
      default:
        return {}; // 默认无特殊位置调整
    }
  };

  const menuVariants = {
    open: {
      opacity: 1,
      scale: 1.03,
      y: 4,
      transition: {
        opacity: {
          duration: 0.25,
          ease: "easeInOut"
        },
        scale: {
          duration: 0.25,
          ease: "easeInOut"
        },
        y: {
          type: 'spring',
          stiffness: 200,
          damping: 20,
          mass: 0.3
        }
      }
    },
    closed: {
      opacity: 0,
      scale: 0.97,
      y: -4,
      transition: {
        opacity: {
          duration: 0.25,
          ease: "easeInOut"
        },
        scale: {
          duration: 0.25,
          ease: "easeInOut"
        },
        y: {
          type: 'spring',
          stiffness: 200,
          damping: 20
        }
      }
    }
  };



  // 修正 MenuItem 的 target 属性
  const validatedMenuItems = menuItems.map(item => ({
    ...item,
    target: item.target === '_blank' || item.target === '_self' ? item.target : '_self'
  }));

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={menuRef}
          initial="closed"
          animate="open"
          exit="closed"
          variants={menuVariants}
          className={`absolute right-0 mt-1 w-36 max-w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none dark:border dark:border-tofu-black-ds/30 dark:bg-tofu-dark-bg ${className}`}
          style={{
            ...getPositionStyles(),
            position: "absolute",
            zIndex: 10,
            padding: "0.5rem 0",
            overflow: "hidden",
          }}
        >
          <div className="px-2">
            {validatedMenuItems.map(item => (
              <a
                key={item.id}
                href={item.href || "#"}
                target={item.target}
                className="block rounded-md px-4 py-2.5
                 text-sm text-tofu-black-icon transition-all duration-300 ease-in-out
                  hover:bg-tofu-blue-lightest hover:text-tofu-blue-2
                dark:text-tofu-light dark:hover:bg-tofu-blue-lightest-dark"
              >
                <span className="flex">
                {item.icon &&
                  <span className="mr-3 size-4">
                  {item.icon}</span>
                }
                {item.text}
                </span>
              </a>
            ))}
          </div>
        </motion.div>
        )}
    </AnimatePresence>
  );
};

export default DropDownMenu;
