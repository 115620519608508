import React from 'react'
import BentoHero from '@/registry/components/tofu/BentoHero'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import '@/styles/globals.css'

const BentoMain = () => {
  //先定义颜色classname一组，text-tofu-main-light-grey dark:tofu-main-light-black
  const textclass =
    'text-3xl text-tofu-main-light-grey dark:tofu-main-light-black'

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
        <div className="space-y-6">
          <div className="space-y-2">
            <p className=" text-sm font-semibold uppercase tracking-wide text-tofu-main-light-text dark:text-tofu-main-dark-text">
              Git-connected Deploys
            </p>
            <h2 className=" text-3xl font-extrabold text-tofu-main-light-black dark:text-tofu-main-dark-white sm:text-4xl">
              From localhost to https, in seconds.
            </h2>
            <p className="dark:text-tofu-main-dark-grey text-3xl text-tofu-main-light-grey">
              Deploy from Git or your CLI.
            </p>
          </div>
          <div className="rounded-lg bg-white p-6 shadow-lg mt-6">
            <div className="mb-4 flex items-center justify-between">
              <div className="flex space-x-1">
                <div className="size-2.5 rounded-full bg-gray-300" />
                <div className="size-2.5 rounded-full bg-gray-300" />
                <div className="size-2.5 rounded-full bg-gray-300" />
              </div>
              <div className="flex space-x-1">
                <div className="size-3 rounded-full bg-red-500" />
                <div className="size-3 rounded-full bg-yellow-500" />
                <div className="size-3 rounded-full bg-green-500" />
              </div>
            </div>
            <div className="text-sm leading-tight">
              <p className="mb-2 text-gray-900">^ ~ /vercel-site/ git push</p>
              <p className="mb-2 text-gray-500">
                Enumerating objects: 1, done.
              </p>
              <p className="mb-2 text-gray-500">
                Counting objects: 100% (1/1), done.
              </p>
              <p className="mb-2 text-gray-500">
                Writing objects: 100% (1/1), 72 bytes, done.
              </p>
              <p className="mb-2 text-gray-500">
                Total 1 (delta 0), reused 0 (delta 0)
              </p>
              <p className="text-gray-900">
                To github.com:vercel/vercel-site.git
              </p>
              <p className="text-gray-900">21326a9..8</p>
            </div>
            <div className="mt-4">
              <img
                src="/placeholder.svg"
                alt="Placeholder image"
                className="h-40 w-80"
              />
            </div>
          </div>
        </div>
        <div className="space-y-6">
          <div className="space-y-2">
            <p className="text-sm font-semibold uppercase tracking-wide text-indigo-600">
              Collaborative pre-production
            </p>
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Every deploy is remarkable. Chat with your team on real,
              production-grade UI, not just designs.
            </h2>
          </div>
          <div className="space-y-4 mt-6">
            <div className="flex items-start space-x-4">
              <Avatar>
                <img src="/placeholder.svg" alt="Mariana" />
                <AvatarFallback>M</AvatarFallback>
              </Avatar>
              <div className="space-y-2">
                <div className="rounded-lg bg-gray-100 p-4">
                  <p className="text-sm">
                    Swapped out the button for some variants we needed.
                  </p>
                </div>
                <Button variant="secondary" className="text-sm">
                  Mariana
                </Button>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Avatar>
                <img src="/placeholder.svg" alt="Rauno" />
                <AvatarFallback>R</AvatarFallback>
              </Avatar>
              <div className="space-y-2">
                <div className="rounded-lg bg-gray-100 p-4">
                  <p className="text-sm">
                    I like it. Does this work with the brand tweaks @almonk?
                  </p>
                </div>
                <Button variant="secondary" className="text-sm">
                  Rauno
                </Button>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Avatar>
                <img src="/placeholder.svg" alt="Mariana" />
                <AvatarFallback>M</AvatarFallback>
              </Avatar>
              <div className="space-y-2">
                <div className="rounded-lg bg-gray-100 p-4">
                  <p className="text-sm">How about this instead?</p>
                </div>
                <Button variant="secondary" className="text-sm">
                  Mariana
                </Button>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Avatar>
                <img src="/placeholder.svg" alt="Rauno" />
                <AvatarFallback>R</AvatarFallback>
              </Avatar>
              <div className="space-y-2">
                <div className="rounded-lg bg-gray-100 p-4">
                  <p className="text-sm">This looks great!</p>
                </div>
                <Button variant="secondary" className="text-sm">
                  Rauno
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BentoMain
