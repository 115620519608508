/**
 * TofuUI Input 输入框
 * @author shuakami
 * @version 1.0.0
 * @copyright ByteFreeze&TofuUI
 */
import React from 'react';

interface TofuInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  type?: string;
  className?: string;
  value?: string; // 可选的值属性
  disabled?: boolean; // 可选的禁用属性
}

const TofuInput: React.FC<TofuInputProps> = ({
                                               placeholder = "Enter text...",
                                               type = "text",
                                               className = "",
                                               value,
                                               disabled,
                                               ...props
                                             }) => {
  return (
    <div className="mx-auto w-full max-w-lg">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        className={`shadow-xs w-full rounded-lg border border-tofu-main-dark-white/70 
                            px-4 
                            py-3 
                            outline-none 
                            transition-all 
                            duration-300 
                            ease-in-out 
                            hover:border-tofu-main-dark-white 
                            focus:border-blue-500 
                            focus:ring 
                            focus:ring-blue-200 
                            focus:ring-opacity-50 
                            dark:border-tofu-main-light-grey/70 
                            dark:hover:border-tofu-main-light-grey 
                            dark:focus:border-blue-500 
                            dark:focus:shadow-md 
                            dark:focus:shadow-blue-300/20 
                            dark:focus:ring-opacity-0 
                            ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        {...props}
      />
    </div>
  );
}

export default TofuInput;
