'use client'

import { Doc } from '@/.contentlayer/generated'
import TechStack from '@/components/tech-stack'
import { buttonVariants } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { useTranslation } from '@/context/TranslationContext'

import AnimatedGradientText from '@/registry/components/magicui/animated-gradient-text'
import { VelocityScroll } from '@/registry/components/magicui/scroll-based-velocity'
import TypingAnimation from '@/registry/components/magicui/typing-animation'
import WordRotate from '@/registry/components/magicui/word-rotate'
import { motion, useInView } from 'framer-motion'
import { Book, ChevronRight, Flame, FolderIcon, Gem } from "lucide-react";
import Link from 'next/link'
import { useRef, useState } from "react";
import BentoMain from '@/main/bento-main'
import Input from "@/registry/components/tofu/input";
import DropDownMenu from "@/registry/components/tofu/dropdown-menu-small";

export default function HeroClient({ post }: { post: Doc }) {
  const { translate } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);
  const menuItems = [
    { id: '1', text: '文件夹', icon: '💾', href: '#b', target: '_blank' },
    { id: '2', text: '数据库', icon: '📁', href: '#s' , target: '_self'}
  ];
  const toggleDropdown = () => setIsOpen(!isOpen);
  const fadeInRef = useRef(null)
  const fadeInInView = useInView(fadeInRef, {
    once: true,
  })

  const fadeUpVariants = {
    initial: {
      opacity: 0,
      y: 24,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  }

  return (
    <section id="hero">
      <div
        className="relative h-full overflow-hidden py-14 dark:!bg-tofu-dark-bg"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          gap: '48px',
          paddingTop: '60px',
          paddingBottom: '100px',
          // background:
          //   'linear-gradient(180deg, #fff, #fff4fa 40%, #fbd9fd 55%, #e2d5ff 70%, #fff 90%)',
        }}
      >
        <div className="container z-10 flex flex-col">
          <div className="grid grid-cols-1">
            <div className="flex flex-col items-center gap-6 pb-8 text-center">
              <motion.div
                ref={fadeInRef}
                animate={fadeInInView ? 'animate' : 'initial'}
                variants={fadeUpVariants}
                initial={false}
                transition={{
                  duration: 0.6,
                  delay: 0.1,
                  ease: [0.21, 0.47, 0.32, 0.98],
                  type: 'spring',
                }}
              >
                <Link href={post.slug}>
                  <AnimatedGradientText>
                    <div
                      className={cn(
                        `absolute inset-0 block h-full w-full animate-gradient bg-gradient-to-r from-[#ffaa40]/50 via-[#9c40ff]/50 to-[#ffaa40]/50 bg-[length:var(--bg-size)_100%] [border-radius:inherit] [mask:linear-gradient(#fff_0_0)_content-box,linear-gradient(#fff_0_0)]`,
                        `p-[1px] ![mask-composite:subtract]`
                      )}
                    />
                    🎉 <Separator className="mx-2 h-4" orientation="vertical" />{' '}
                    <span
                      className={cn(
                        `animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`,
                        `inline`
                      )}
                    >
                      Introducing {post.title}
                    </span>
                    <ChevronRight className="ml-1 h-4 w-4 text-gray-500" />
                  </AnimatedGradientText>
                </Link>
              </motion.div>

              <motion.h1
                animate={fadeInInView ? 'animate' : 'initial'}
                variants={fadeUpVariants}
                initial={false}
                transition={{
                  duration: 0.6,
                  delay: 0.1,
                  ease: [0.21, 0.47, 0.32, 0.98],
                  type: 'spring',
                }}
                className=" bg-gradient-to-br from-black from-30% to-black/60 bg-clip-text py-6 font-semibold leading-none -tracking-tight dark:from-white dark:to-white/40 sm:text-6xl md:text-6xl lg:text-6xl text-6xl"
              >
                最 “方便” 的UI
                <br></br>
                快捷、优秀、简单
              </motion.h1>

              <motion.p
                className="-mt-7 !max-w-[50rem] w-full text-balance text-lg tracking-tight text-gray-500 md:text-xl"
                animate={fadeInInView ? 'animate' : 'initial'}
                variants={fadeUpVariants}
                initial={false}
                transition={{
                  duration: 0.6,
                  delay: 0.2,
                  ease: [0.21, 0.47, 0.32, 0.98],
                  type: 'spring',
                }}
              >
                Tofu UI is a modern UI library that provides a set of
                high-quality, reusable, and customizable components. So, you can
                easily build your own UI with Tofu UI.
              </motion.p>

              <motion.div
                animate={fadeInInView ? 'animate' : 'initial'}
                variants={fadeUpVariants}
                className="flex flex-col gap-4 lg:flex-row"
                initial={false}
                transition={{
                  duration: 0.6,
                  delay: 0.3,
                  ease: [0.21, 0.47, 0.32, 0.98],
                  type: 'spring',
                }}
              >
                <div className="mt-4 flex flex-col gap-4 md:flex-row">
                  <Link
                    href="/components"
                    target="_blank"
                    className={cn(
                      'h-12 py-6 px-8 flex flex-row justify-center items-center text-sm font-medium rounded-lg cursor-pointer select-none touch-none user-drag-none',
                      'bg-tofu-purple-lightest text-tofu-purple hover:bg-tofu-purple-lightest/20 transition-all duration-200 ease-in-out'
                    )}
                  >
                    <Book className="mr-2 size-4 flex-shrink-0 transition-all duration-300 ease-out " />
                    Browse Components
                  </Link>
                  <Link
                    href="/docs"
                    className={cn(
                      'h-12 py-6 px-8 flex flex-row justify-center items-center text-sm font-medium rounded-lg cursor-pointer select-none touch-none user-drag-none',
                      'bg-tofu-gray-600 text-tofu-black hover:bg-tofu-gray-750 transition-all duration-200 ease-in-out'
                    )}
                  >
                    <Flame className="mr-2 size-4 flex-shrink-0" />
                    Pro Version
                  </Link>
                </div>
              </motion.div>

              <Input
                placeholder="Enter your name"
                disabled={true}
              />

              <div className="relative">
                <button onClick={toggleDropdown}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600">
                  + 新建
                </button>
                <DropDownMenu
                  position="bottom"
                  isOpen={isOpen}
                  menuItems={menuItems}
                  onClose={() => setIsOpen(false)}
                />
              </div>
              {/* <motion.div
                animate={fadeInInView ? "animate" : "initial"}
                variants={fadeUpVariants}
                initial={false}
                transition={{
                  duration: 0.6,
                  delay: 0.3,
                  ease: [0.21, 0.47, 0.32, 0.98],
                  type: "spring",
                }}
              >
                <div className="container flex flex-col items-center justify-center gap-2 sm:flex-row">
                  <div className="flex flex-row items-center justify-center -space-x-4">
                    <img
                      src="https://randomuser.me/api/portraits/women/72.jpg"
                      className="h-10 w-10 rounded-full border-2 border-white"
                    />
                    <img
                      src="https://randomuser.me/api/portraits/men/94.jpg"
                      className="h-10 w-10 rounded-full border-2 border-white"
                    />
                    <img
                      src="https://randomuser.me/api/portraits/women/34.jpg"
                      className="h-10 w-10 rounded-full border-2 border-white"
                    />
                    <img
                      src="https://randomuser.me/api/portraits/men/86.jpg"
                      className="h-10 w-10 rounded-full border-2 border-white"
                    />
                    <img
                      src="https://randomuser.me/api/portraits/women/41.jpg"
                      className="h-10 w-10 rounded-full border-2 border-white"
                    />
                  </div>

                  <div className="flex flex-col gap-x-2">
                    <div className="mx-auto flex flex-row items-center justify-start">
                      <StarFilledIcon className="h-4 w-4 text-orange-300" />
                      <StarFilledIcon className="h-4 w-4 text-orange-300" />
                      <StarFilledIcon className="h-4 w-4 text-orange-300" />
                      <StarFilledIcon className="h-4 w-4 text-orange-300" />
                      <StarFilledIcon className="h-4 w-4 text-orange-300" />
                      <span className="ml-2 text-base font-bold">5.0</span>
                    </div>

                    <span className="inline-block text-center text-xs font-medium leading-snug tracking-tighter">
                      <NumberTicker
                        value={500}
                        className="font-extrabold"
                        delay={0.2}
                      />
                      + developers making beautiful landing pages
                    </span>
                  </div>
                </div>
              </motion.div> */}
            </div>
          </div>

          <div className="container relative mx-auto mt-32 w-full py-5 px-5">
            <BentoMain />
          </div>
        </div>
      </div>
    </section>
  )
}
